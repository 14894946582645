import {Launcher} from './launcher'

import * as data from '../data'

function mapState(state) {
  return {
    launchMethod: data.getLaunchMethod(state),
  }
}

const connector = data.connect(mapState)

export default connector(Launcher)
