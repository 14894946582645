import PropTypes from 'prop-types'
import React from 'react'
import * as data from '../data'
import { useLocation } from 'react-router-dom'
import querystring from 'querystring'
import * as R from 'ramda'

/**
 * Description: 
 * The purpose of this component is to center
 * and maintain the aspect ratio some games can't 
 * handle this.
 */

export function GameWrapper(props) {
  const location = useLocation()
  const game = data.useGame()
  const query = querystring.parse(R.tail(location.search))
  const shouldEnforceAspectRatio = game.forceAspectRatio && R.equals('browser', query.client_type)

  if(!shouldEnforceAspectRatio) {
    return props.children
  }

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      height: '100%'
    }}>
      <div style={{
        position: 'relative',
        height:'100%',
        paddingLeft: `calc(${game.height}/${game.width} * 237vmin)`,
        maxWidth: '100%'
      }}>
        <div style={{
          position: 'absolute',
          maxWidth: '100vw',
          margin: 'auto',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          width: '100%',
          height: '100%',
          maxHeight: `calc((100vw / (${game.height}/(${game.height}/1.5))))`,
        }}>
          {props.children}
        </div>
      </div>
    </div>
  )
}

GameWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}
