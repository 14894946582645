import loadable from '@loadable/component'
import PropTypes from 'prop-types'
import React from 'react'
import { GameWrapper } from './game-wrapper'

const IframeLauncher = loadable(() => import('./iframe-launcher'))
const LottolandLauncher = loadable(() => import('./lottoland-launcher'))
const NetEntLauncher = loadable(() => import('./net-ent-launcher'))

export function Launcher(props) {
  switch (props.launchMethod) {
    case 'iframe': {
      return (
        <GameWrapper>
          <IframeLauncher />
        </GameWrapper>
      )
    }
    case 'lottoland-launcher': {
      return (
        <GameWrapper>
          <LottolandLauncher />
        </GameWrapper>
      )
    }
    case 'net-ent-launcher': {
      return (
        <GameWrapper>
          <NetEntLauncher />
        </GameWrapper>
      )
    }
    default: {
      return <pre>Unsupported launch method: {props.launchMethod}</pre>
    }
  }
}

Launcher.propTypes = {
  launchMethod: PropTypes.oneOf([
    'iframe',
    'lottoland-launcher',
    'net-ent-launcher',
  ]),
}
